import React, { useEffect, useState } from 'react';
import Header from './Header';
import './History.css';
import config from './config.json';
import { FaDownload } from 'react-icons/fa';

const History = ({  isModalOpen, isRegisterModalOpen, setIsRegisterModalOpen, setIsModalOpen, setIsLoggedIn, setUsername, setAccessKey, remainingCredits }) => {
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10;

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const storedEmail = localStorage.getItem('email');
        const accessToken = localStorage.getItem('access_token');

        const historyResponse = await fetch(`${config.DATASCIENCE_API_SUITE}/resume/forensics/get-bulk-duplicate-check-history`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: storedEmail,
          }),
        });

        const historyData = await historyResponse.json();
        if (historyData.status === 200) {
          const sortedHistory = historyData.data.history.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
          setHistoryData(sortedHistory);
        } else {
          throw new Error('Failed to fetch results data. Please login to see the results.');
        }

        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchHistory();
  }, []);

  const handleDownloadDiscrepancy = async (discrepancyType, forensicsId, email) => {
    const accessToken = localStorage.getItem('access_token');

    try {
      const response = await fetch(
        `${config.DATASCIENCE_API_SUITE}/resume/forensics/retrieve-resumes-by-discrepancy?email=${email}&forensics_id=${forensicsId}&discrepancy=${discrepancyType}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch results');
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `resumes_${discrepancyType}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  const handleDownload = async (email, userId) => {
    const accessToken = localStorage.getItem('access_token');

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/resume/forensics/get-result-excel?email=${email}&forensics_id=${userId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch results');
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'results.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  const totalEntries = historyData.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);
  const currentEntries = historyData.slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="historyPage-container">
      <Header isModalOpen={isModalOpen} isRegisterModalOpen={isRegisterModalOpen} setIsRegisterModalOpen={setIsRegisterModalOpen} setIsModalOpen={setIsModalOpen} setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} setAccessKey={setAccessKey} remainingCredits={remainingCredits} />

      <main className="historyPage-main">
        {isLoading ? (
          <div className="loader"></div>
        ) : error ? (
          <div className="error-message">Error: {error}</div>
        ) : (
          <section className="historyPage-section">
            <h2 className="historyPage-title">Duplicate Check History</h2>
            <div className="historyPage-table-wrapper">
              <table className="historyPage-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Resumes Detected</th>
                    <th>Good Resumes</th>
                    <th>Name Discrepancy</th>
                    <th>Work Experience Discrepancy</th>
                    <th>Education Discrepancy</th>
                    <th>Personal Info Discrepancy</th>
                    <th>Duplicate Groups</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((entry) => (
                    <tr key={entry._id}>
                      <td>{new Date(entry.created_date).toLocaleDateString()}</td>
                      <td>{entry.num_files || 0}</td>
                      <td>{entry.clean_resumes || 0}</td>

                      {/* Make each discrepancy clickable */}
                      {['name', 'work_experience', 'education', 'personal_info'].map((discrepancyType) => (
                        <td key={discrepancyType}>
                          {entry.discrepancies?.[discrepancyType]?.value > 0 ? (
                            <span
                              className="clickable-discrepancy"
                              onClick={() => handleDownloadDiscrepancy(discrepancyType, entry._id, entry.user_id)}
                              style={{ cursor: 'pointer', color: '#672024', fontWeight: '700' }}
                            >
                              {entry.discrepancies?.[discrepancyType]?.value}
                            </span>
                          ) : (
                            entry.discrepancies?.[discrepancyType]?.value || 0
                          )}
                        </td>
                      ))}

                      <td>{entry.num_duplicate_groups || 0}</td>
                      <td>
                      <FaDownload
                          className="download-icon"
                          style={{ cursor: 'pointer', fontSize: '1rem', color: '#672024' }}
                          onClick={() => handleDownload(entry.user_id, entry._id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </section>
        )}
      </main>

      <footer className="footer">
        <p>Resume Forensics - A MayaMaya Product</p>
      </footer>
    </div>
  );
};

export default History;
