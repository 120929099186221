import React, { useState, useEffect } from "react";
import './ProgressResultsSection.css';
import config from './config.json';
import { Link, useNavigate } from "react-router-dom";

function ProgressResultsSection({ triggerPopup, processStatus, setIsLoggedIn, setUsername, setAccessKey, uploadedFiles, totalResumes, totalDuplicates, toggleModal, driveLink, isLoggedIn, username, accessKey, remainingCredits, setRemainingCredits, userId }) {
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [progress, setProgress] = useState(0); // Progress in percentage
  const [count, setCount] = useState(0); // Count of resumes being processed
  const navigate = useNavigate();
  const [hasLoggedOut, setHasLoggedOut] = useState(false);

  // Function to check user's credit status using API
  const checkCreditStatus = async (totalApiHits) => {
    try {
      const response = await fetch(`${config.AUTH_BASE_URL}/api/credit/check-status`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          product_name: "RESUME_FORENSICS",
          total_api_hits: totalApiHits,
        }),
      });

      const creditData = await response.json();
      if (creditData.msg && creditData.msg.includes("Bad Authorization header") && !hasLoggedOut) {
        handleLogout(); // Logout if the message indicates a bad authorization header
        setHasLoggedOut(true);}
      if (response.ok && creditData.status === 200) {
        setRemainingCredits(creditData.data.credits);
        return creditData.data.credits;
      } else {
        triggerPopup(creditData.message || "Error checking credit status");
        return false;
      }
    } catch (error) {
      console.error("Error checking credit status:", error);
      return false;
    }
  };

  // Function to handle Get Results button click
  const handleGetResultsClick = async () => {
    if (!isLoggedIn) {
      toggleModal(); // Open the login modal if not logged in
      return;
    }

    if (uploadedFiles.length < 2 && !driveLink) {
      triggerPopup("Please upload at least 2 files to proceed.");
      return;
    }

    const totalApiHits = uploadedFiles.length;

    if (remainingCredits < totalApiHits) {
      triggerPopup("You do not have enough credits to perform this action.");
      return;
    }

    setIsAnalyzing(true);
    setCount(uploadedFiles.length);

    // Start the progress bar for 90 seconds
    const totalDuration = 90 * 1000; // 90 seconds in milliseconds
    const intervalDuration = 1000; // Update every second
    let elapsed = 0;

    const progressInterval = setInterval(() => {
      elapsed += intervalDuration;
      const progressPercentage = Math.min((elapsed / totalDuration) * 100, 100);
      setProgress(progressPercentage);

      if (elapsed >= totalDuration) {
        clearInterval(progressInterval);
        setIsAnalyzing(false);
        setShowResults(true);
        setResultMessage("Processing Resumes. You will receive an email shortly after completion.");
      }
    }, intervalDuration);

    // Perform API call for analysis
    try {
      const formData = new FormData();
      const storedEmail = localStorage.getItem("email");
      formData.append('email', storedEmail);
      formData.append('web_app', true);
      formData.append('user_id', userId);
      if (uploadedFiles.length > 0) {
        uploadedFiles.forEach((file) => {
          formData.append('files', file);
        });
      } else if (driveLink) {
        formData.append('link', driveLink);
      }

      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/resume/forensics/bulk-duplicate-check`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessKey}`,
        },
        body: formData,
      });

      if (response.ok) {
        const hasCredits = await checkCreditStatus(totalApiHits);
        const result = await response.json();
        setResultMessage(result.message);
      } else {
        throw new Error("Failed to analyze resumes");
      }
    } catch (error) {
      console.error("Error analyzing resumes:", error);
      triggerPopup(resultMessage);
      setIsAnalyzing(false);
      clearInterval(progressInterval);
    }

    // Cleanup on unmount
    return () => clearInterval(progressInterval);
  };

  // Function to handle analyze again button click
  function handleAnalyzeAgainClick() {
    window.scrollTo(0, 0);
    navigate("/history");
  }

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername(""); // Clear username on logout
    setAccessKey(""); // Clear access key on logout
    localStorage.removeItem("access_token"); // Remove access token from localStorage
    localStorage.removeItem("email");
    localStorage.removeItem("uploadedFiles"); // Remove uploaded files from localStorage
    localStorage.removeItem("driveLink");
    window.location.href = "/"; // Redirect to homepage
  };

  return (
    <div>
      <section className="progress-results-section">
      {!showResults && !isAnalyzing && (
          <>
            <button
              className={`get-results-button ${processStatus === 'pending' ? 'disabled' : ''}`}
              onClick={processStatus !== 'pending' ? handleGetResultsClick : undefined}
              disabled={processStatus === 'pending'}
            >
              Check
            </button>
            {processStatus === 'pending' && (
              <p className="processing-message" style={{ color: "#777", marginTop: "20px", fontSize: "1rem" }}>
                The previous batch of resumes is still processing. Please wait until it's complete before checking another batch.
              </p>
            )}
          </>
        )}
        
        {isAnalyzing && (
          <div>
            <p className="email-notification" style={{ fontSize: "1.2rem", lineHeight: "1.5", color: "#333", marginBottom: "-20px" }}>
            You will receive an email shortly when the results are ready to download.
        </p>
            <div className="progress-bar">
              <div className="progress-fill" style={{ width: `${progress}%` }}></div>
            </div>
            <p style={{ fontSize: "1.1rem", color: "#333", marginTop: "-20px" }}>
              {driveLink && !uploadedFiles.length ? `Analyzing resumes from the drive link... ${Math.floor(progress)}%` : `Analyzing ${count} Resumes... ${Math.floor(progress)}%`}
            </p>
          </div>
        )}
        {showResults && (
          <div className="">
            {/*<p className="email-notification" style={{ fontSize: "1.1rem", color: "#333" }}>
              {resultMessage}
        </p>*/}
            <button className="get-results-button" onClick={handleAnalyzeAgainClick}>
              Go to Results
            </button>
          </div>
        )}
      </section>
    </div>
  );
}

export default ProgressResultsSection;
