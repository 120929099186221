import React, { useState, useEffect  } from "react";
import './UploadSection.css';
import config from './config.json';
import logo from './logo.svg';
import { Link, useNavigate }  from "react-router-dom"; // Import Link for navigation
import ProgressResultsSection from './ProgressResultsSection';
import { FaEye, FaEyeSlash, FaCloudUploadAlt } from 'react-icons/fa';

// Registration function
async function registerUser(userData) {
  try {
    const response = await fetch(`${config.AUTH_BASE_URL}/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json(); // Parse response JSON

    // Update the success condition to check for status 200
    if (data.status === 200) {
      return { success: true, data }; // Successful registration
    } else if (data.status === 400 && data.message === "User already exist") {
      return { success: false, error: "User already exists" }; // Return user already exists error
    } else {
      throw new Error(`${data.message}`);
    }
  } catch (error) {
    return { success: false, error: error.message }; // Return error object
  }
}

async function login(username, password) {
  try {
    const response = await fetch(`${config.AUTH_BASE_URL}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: username,
        password: password,
        product_name : "RESUME_FORENSICS"
      }),
    });

    if (response.ok) {
      const data = await response.json();
      if (data.access_token) {
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("email", username);
        return data; // Login successful
      } else {
        return null;
      }
    } else if (response.status === 401) {
      return null;
    } else {
      throw new Error(`Login failed: ${response.statusText}`);
    }
  } catch (error) {
    return null;
  }
}

// New function to handle forgot password
async function handleForgotPassword(email) {
  try {
    const response = await fetch(`${config.AUTH_BASE_URL}/api/user/send-mail/forgot-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        suite_product: "RESUME-FORENSICS",
      }),
    });

    const data = await response.json();
    return data; // Return the response data
  } catch (error) {
    console.error("Error sending forgot password email:", error);
    return null;
  }
}


function UploadSection({ setIsRegisterModalOpen, isRegisterModalOpen, setIsModalOpen, isModalOpen, processStatus, setUploadedFiles, setDriveLink, setIsLoggedIn, setUsername, setAccessKey, uploadedFiles, accessKey, remainingCredits, setRemainingCredits, userId }) {
  const [driveLink, setDriveLinkLocal] = useState("");
  const [isLoggedIn, setIsLoggedInLocal] = useState(false);
  const [username, setUsernameLocal] = useState(""); // For login
  const [email, setEmail] = useState(""); // For registration
  const [password, setPassword] = useState("");
  const [files, setFiles] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showPassword, setShowPassword] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedAccessToken = localStorage.getItem("access_token");

    if (storedEmail && storedAccessToken) {
      setIsLoggedIn(true);
      setIsLoggedInLocal(true);
      setUsernameLocal(storedEmail);
      setAccessKey(storedAccessToken); // Set access token if available
    }
  }, [setIsLoggedIn, setAccessKey]);

  useEffect(() => {
    const storedFiles = JSON.parse(localStorage.getItem('uploadedFiles'));
    const storedDriveLink = localStorage.getItem('driveLink');
  
    if (storedFiles) {
      setFiles(storedFiles.map((fileName) => ({ name: fileName }))); // Restore file names
      setUploadedFiles(storedFiles.map((fileName) => ({ name: fileName }))); // Restore uploadedFiles state
    }
  
    if (storedDriveLink) {
      setDriveLinkLocal(storedDriveLink);
      setDriveLink(storedDriveLink); // Restore the drive link
    }
  }, [setUploadedFiles, setDriveLink]);
  
  // Update localStorage whenever files or drive link are changed
  

  const handleProfile = () => {
    navigate("/profile"); // Navigate to profile page
  };

  const handleBillings = () => {
    navigate("/pricing"); // Navigate to pricing/billings page
  };

  const handleFileUpload = (fileList) => {
    const fileArray = Array.from(fileList);
    
    // Save the uploaded files in local state as file objects
    setFiles(fileArray);
    setUploadedFiles(fileArray);
  
    // Save the uploaded files in localStorage
    //localStorage.setItem('uploadedFiles', JSON.stringify(fileArray.map(file => file.name)));
  
  
    // Clear any drive link input if files are uploaded
    setDriveLinkLocal("");
    setDriveLink("");
  };
  
  
  // Handle Drive link input change
  const handleLinkInputChange = (event) => {
    const link = event.target.value;
    setDriveLinkLocal(link);
    setDriveLink(link);

    // Clear files when a link is entered
    setFiles([]);
    setUploadedFiles([]);
    localStorage.removeItem('uploadedFiles'); // Clear local storage if a drive link is entered
  };


  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Toggle password visibility
const toggleShowPassword = () => {
  setShowPassword(!showPassword);
};

const handleLogin = async () => {
  const response = await login(username, password);
  if (response) {
    // Only set logged-in state if response is valid
    setIsLoggedIn(true);
    setIsLoggedInLocal(true);
    setUsername(username);
    setAccessKey(response.access_token); // Assuming access token is used as access key
    setIsModalOpen(false);
    //window.location.reload();
  } else {
    // If response is null or there's an error
    setIsLoggedIn(false);
    setIsLoggedInLocal(false);
    triggerPopup("Login failed"); // This alert can be improved based on your UX design
  }
};

const handleRegister = async () => {
  if (!firstName || !lastName || !email || !password) {
    triggerPopup("Please fill in all required fields.");
    return;
  }

  const response = await registerUser({
    email: email,
    password: password,
    first_name: firstName,
    last_name: lastName,
    org_name: orgName,
    product_name: "RESUME_FORENSICS",
  });


  if (response.success) {
    triggerPopup("Registration successful!");
    setIsRegisterModalOpen(false);

    // Immediately attempt login using the same credentials
    const loginResponse = await login(email, password); // Use email and password directly for login
    if (loginResponse) {
      console.log("Login successful after registration");
      setIsLoggedIn(true);
      setIsLoggedInLocal(true);
      setUsername(email); // Set the username to email
      setAccessKey(loginResponse.access_token); // Assuming access token is used as access key
      setIsModalOpen(false);      
    } else {
      triggerPopup("Login failed after registration. Please try again.");
    }
  } else {
    triggerPopup(`Registration failed: ${response.error}`);
  }
};


const triggerPopup = (message) => {
  setPopupMessage(message);
  setShowPopup(true);
  setTimeout(() => {
    setShowPopup(false);
  }, 3000); // Display the popup for 3 seconds
};

const handleClosePopup = () => {
  setShowPopup(false);
  setPopupMessage(""); // Optionally clear the popup message if needed
};

const handleForgotPasswordClick = async () => {
    if (!username) {
      triggerPopup("Please enter your email address.");
      return;
    }

    const response = await handleForgotPassword(username); // Use username as email
    if (response) {
      triggerPopup("Forgot password email sent! Check your inbox.");
      setUsername(""); // Clear the email input
    } else {
      triggerPopup("Failed to send forgot password email.");
    }
  };
  

  const handleLogout = () => {
    setIsLoggedIn(false);
    setIsLoggedInLocal(false);
    setUsername(""); // Clear username on logout
    setAccessKey(""); // Clear access key on logout
    localStorage.removeItem("access_token"); // Remove access token from localStorage
    localStorage.removeItem("email");
    localStorage.removeItem("uploadedFiles"); // Remove uploaded files from localStorage
    localStorage.removeItem("driveLink");
    window.location.href = "/";
  };

  // Handle history click
  const handleHistoryClick = () => {
    if (!isLoggedIn) {
      toggleModal(); // Show login modal if not logged in
    } else {
      navigate("/history"); // Navigate to history page if logged in
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogo = () => {
    navigate("/");
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setMenuOpen(false); // Automatically close the menu on larger screens
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  

  return (
    <div>
      <div className="upload-header">
        {/* Logo */}
        <img src={logo} alt="Logo" className="logo" onClick={handleLogo} />

        {/* Remaining Credits */}
      {isLoggedIn && (
        <div className="credits-info">
          Credits: <span>{remainingCredits}</span>
        </div>
      )}
        <>
          <div className="hamburger" onClick={toggleMenu}>
            <span className={`hamburger-icon ${menuOpen ? 'open' : ''}`}></span>
          </div>
          {menuOpen && (
            <nav className="mobile-menu">
              <ul>
                {!isLoggedIn ? (
                  <>
                    <li><p onClick={() => setIsModalOpen(true) } style={{cursor: "pointer"}}>Login</p></li>
                    <li><p onClick={() => setIsRegisterModalOpen(true)}style={{cursor: "pointer"}}>Register</p></li>
                  </>
                ) : (
                  <>
                    <li><a href="/profile">My Profile</a></li>
                    <li><a href="/history">My Results</a></li>
                    <li><a href="/pricing">Billing</a></li>
                    <li><a href="/" onClick={handleLogout}>Logout</a></li>
                  </>
                )}
              </ul>
            </nav>
          )}
        </>

      </div>

      <div className="upload-section-title">
      
      {showPopup && (
    <div>
      <div className="overlay" onClick={handleClosePopup}></div>
      <div className="popup">
        <button className="close-btn" onClick={handleClosePopup}>×</button>
        {popupMessage}
      </div>
    </div>
  )}

        <h1 className="pricing-title">
        Every resume tells a story, but not all stories are truthful. At MayaMaya Resume Forensics, we unravel the narratives hidden between the lines, deciphering the subtle clues of authenticity.
        </h1>
      </div>

      <section className="upload-section">
        {/* Login Modal */}
        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <div className="modal-left">
                <h3>Welcome!</h3>
                <p>Please login to continue.</p>
              </div>
              <div className="modal-right">
                <button className="close-btn" onClick={() => setIsModalOpen(false)}>
                  X
                </button>
                <h3>Login</h3>
                <input
                  type="text"
                  placeholder="Email"
                  value={username}
                  onChange={(e) => setUsernameLocal(e.target.value)}
                />
                
                <div className="password-input">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span onClick={toggleShowPassword} className="eye-icon">
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
                <button className="modal-btn" onClick={handleLogin}>Login</button>
                <div>
                  <p style={{ marginTop: '15px', fontSize: '14px', color: "#555" }}>Forgot your password? <span onClick={handleForgotPasswordClick} style={{ cursor: "pointer", color: "#007bff" }}>Click here</span></p>
                </div>
                <p style={{ marginTop: '15px', fontSize: '14px', color: "#555" }}>
  Don't have an account? 
  <a
    href="#"
    onClick={() => {
      setIsModalOpen(false);
      setIsRegisterModalOpen(true);
    }}
    style={{ color: '#007bff', textDecoration: 'none' }}
    onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
    onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
  >
     Register here
  </a>
</p>

              </div>
            </div>
          </div>
        )}

        {/* Registration Modal */}
        {isRegisterModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <div className="modal-left">
                <h3>Join Us!</h3>
                <p>Create your account and start your journey.</p>
              </div>
              <div className="modal-right">
                <button
                  className="close-btn"
                  onClick={() => setIsRegisterModalOpen(false)}
                >
                  X
                </button>
                <h3>Register</h3>
                <input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Organization Name"
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Email"
                  value={email} // Use email state here
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="password-input">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span onClick={toggleShowPassword} className="eye-icon">
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
                <button className="modal-btn" onClick={handleRegister}>Register</button>
                <p style={{ marginTop: '15px', fontSize: '14px', color: "#555" }}>
  Already have an account? 
  <a
    href="#"
    onClick={() => {
      setIsRegisterModalOpen(false);
      setIsModalOpen(true);
    }}
    style={{ color: '#007bff', textDecoration: 'none' }}
    onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
    onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
  >
    Login here
  </a>
</p>

              </div>
            </div>
          </div>
        )}

          
        <div className="upload-text">
          <h2>Resume <span>Forensics</span></h2>
          <p>
          You deserve to win back your time by streamlining your recruitment with the precision of a detective. </p>
          <p>Upload resumes or drive links, and let our AI-powered forensics uncover duplicates, fakes, or hidden identities. You will ensure dealing with only the most genuine candidates.</p>
          <p>Pay for what you use. No more!!
          </p>
          
        </div>

        <div className="upload-area">
          <h2>Please Upload Resumes </h2>

          {/* Drag-drop box made clickable */}
          <div
            className="drag-drop-box"
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              handleFileUpload(e.dataTransfer.files);
            }}
            onClick={() => document.getElementById('file-upload').click()} // Make box clickable
          >
            
            <p><p><FaCloudUploadAlt className="upload-icon" /></p>Drag & drop resumes here or click to browse<p className="file-types">Accepts PDF or DOCX</p></p>
            
          </div>

          <input
            type="file"
            id="file-upload"
            style={{ display: "none" }}
            accept=".pdf, .docx"
            multiple
            onChange={(e) => handleFileUpload(e.target.files)}
          />

<h2>Or Drive Link</h2>

          <input
            type="text"
            className="drive-link-input"
            placeholder="Insert Sharable Drive Link (https://… example)"
            value={driveLink}
            onChange={handleLinkInputChange}
          />

          {/* Display uploaded files or entered Drive link */}
          <div className="file-list">
          {files.length > 0 ? (
    <ul>
      {files.map((file, index) => (
        <li key={index}>{file.name}</li> // Accessing the name property correctly
      ))}
    </ul>
  ) : (
    <p></p>
  )}
            <ProgressResultsSection
                  uploadedFiles={uploadedFiles}
                  driveLink={driveLink}
                  isLoggedIn={isLoggedIn}
                  username={username}
                  accessKey={accessKey}
                  remainingCredits={remainingCredits} // Pass remaining credits here
                  setRemainingCredits={setRemainingCredits}
                  userId={userId}
                  toggleModal={toggleModal}
                  processStatus={processStatus}
                  triggerPopup={triggerPopup}
                  setIsLoggedIn={setIsLoggedIn}
                  setUsername={setUsername}
                  setAccessKey={setAccessKey}
                />
          </div>
        </div>
      </section>
      <footer className="footer">
          <p>
            Resume Forensics - A MayaMaya Product
          </p>
        </footer>
    </div>
  );
}

export default UploadSection;
