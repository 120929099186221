import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UploadSection from './components/UploadSection';
import Pricing from './components/Pricing';
import Profile from './components/Profile';
import History from './components/History';
import ResetPassword from './components/PasswordReset';
import './App.css';
import config from './components/config.json'; // Assuming the config file is in components folder

function App() {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [driveLink, setDriveLink] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [accessKey, setAccessKey] = useState("");
  const [remainingCredits, setRemainingCredits] = useState(0);
  const [subscriptionType, setSubscriptionType] = useState('');
  const [userId, setUserId] = useState('');
  const [processStatus, setProcessStatus] = useState('');
  const [totalResumes, setTotalResumes] = useState(0);
  const [totalDuplicates, setTotalDuplicates] = useState(0);
  const [historyData, setHistoryData] = useState([]);
  const [hasLoggedOut, setHasLoggedOut] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

  // Function to fetch process status
  const fetchProcessStatus = async () => {
    if (!accessKey || !userId) return; // Only fetch if accessKey and userId exist
    try {
      const response = await fetch(`${config.AUTH_BASE_URL}/api/credit/check-status`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          product_name: "RESUME_FORENSICS",
          total_api_hits: 0,
        }),
      });

      const data = await response.json();

      if (data.msg && data.msg.includes("Bad Authorization header") && !hasLoggedOut) {
        handleLogout(); // Logout if the message indicates a bad authorization header
        setHasLoggedOut(true); // Set to true to avoid multiple logouts
      } else if (data.status === 200 && data.data.process_status !== processStatus) {
        setProcessStatus(data.data.process_status); // Update processStatus only if it has changed
      }
    } catch (error) {
      console.error('Error fetching process status:', error);
    }
  };

  useEffect(() => {
    const fetchUserCreditsAndSubscription = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        const email = localStorage.getItem("email");

        if (!accessToken || !email) return;

        const profileResponse = await fetch(`${config.AUTH_BASE_URL}/api/user/profile`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        const profileData = await profileResponse.json();

        if (profileData.status === 200) {
          const userId = profileData.data.user_profile.user_id;
          setUserId(userId);

          // Fetch the credits info
          const creditsResponse = await fetch(`${config.AUTH_BASE_URL}/api/user/product`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              user_id: userId,
              product_code: 'RESUME_FORENSICS',
            }),
          });

          const creditsData = await creditsResponse.json();

          if (creditsData.status === 200) {
            const productDetails = creditsData.data.user_product_details;
            setRemainingCredits(productDetails.credits || 0);
            setSubscriptionType(productDetails.subscription_type || '');
          } else {
            throw new Error('Failed to fetch credits data');
          }
        } else {
          throw new Error('Failed to fetch profile data');
        }
      } catch (err) {
        console.error('Error fetching user data:', err.message);
      }
    };

    fetchUserCreditsAndSubscription();

    if (isLoggedIn) {
      fetchProcessStatus(); // Fetch the status after logging in
    }

    const intervalId = setInterval(fetchProcessStatus, 90 * 1000); // Fetch process status every 90 seconds

    return () => clearInterval(intervalId); // Cleanup the interval

  }, [isLoggedIn, accessKey, userId]); // Dependencies: call only when `isLoggedIn`, `accessKey`, or `userId` changes

  // Function to fetch history data without visiting the history page
  const fetchHistoryData = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const email = localStorage.getItem("email");

      if (!accessToken || !email) return;

      const historyResponse = await fetch(`${config.DATASCIENCE_API_SUITE}/resume/forensics/get-bulk-duplicate-check-history`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: email }),
      });

      const historyData = await historyResponse.json();

      if (historyData.status === 200) {
        const history = historyData.data.history;

        let totalResumes = 0;
        let totalDuplicates = 0;

        // Calculate total resumes and duplicates
        totalResumes = history.reduce((total, entry) => total + entry.filenames.length, 0);
        totalDuplicates = history.reduce((total, entry) => total + entry.total_duplicates, 0);

        setTotalResumes(isNaN(totalResumes) ? 0 : totalResumes);
        setTotalDuplicates(isNaN(totalDuplicates) ? 0 : totalDuplicates);

        setHistoryData(history); // Save history data
      } else {
        throw new Error('Failed to fetch history data');
      }
    } catch (err) {
      console.error('Error fetching history data:', err.message);
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername(""); // Clear username on logout
    setAccessKey(""); // Clear access key on logout
    localStorage.removeItem("access_token"); // Remove access token from localStorage
    localStorage.removeItem("email");
    localStorage.removeItem("uploadedFiles"); // Remove uploaded files from localStorage
    localStorage.removeItem("driveLink");
    window.location.href = "/"; // Redirect to homepage
  };

  return (
    <Router>
      <div className="App">
        <main>
          <Routes>
            <Route path="/" element={
              <UploadSection
                setUploadedFiles={setUploadedFiles}
                setDriveLink={setDriveLink}
                setIsLoggedIn={setIsLoggedIn}
                setUsername={setUsername}
                setAccessKey={setAccessKey}
                remainingCredits={remainingCredits}
                uploadedFiles={uploadedFiles}
                driveLink={driveLink}
                isLoggedIn={isLoggedIn}
                username={username}
                accessKey={accessKey}
                setRemainingCredits={setRemainingCredits}
                userId={userId}
                totalResumes={totalResumes} // Pass total resumes
                totalDuplicates={totalDuplicates} // Pass total duplicates
                processStatus={processStatus}
                setIsRegisterModalOpen={setIsRegisterModalOpen}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                isRegisterModalOpen={isRegisterModalOpen}
              />
            } />
            <Route path="/pricing" element={
              <Pricing
                setIsLoggedIn={setIsLoggedIn}
                setUsername={setUsername}
                setAccessKey={setAccessKey}
                remainingCredits={remainingCredits}
                subscriptionType={subscriptionType} // Pass subscription type to Pricing page
                setIsRegisterModalOpen={setIsRegisterModalOpen}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                isRegisterModalOpen={isRegisterModalOpen}
              />
            } />
            <Route path="/profile" element={
              <Profile
                setIsLoggedIn={setIsLoggedIn}
                setUsername={setUsername}
                setAccessKey={setAccessKey}
                accessKey={accessKey}
                remainingCredits={remainingCredits}
                setIsRegisterModalOpen={setIsRegisterModalOpen}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                isRegisterModalOpen={isRegisterModalOpen}
              />
            } />
            <Route path="/history" element={
              <History
                setIsLoggedIn={setIsLoggedIn}
                setUsername={setUsername}
                setAccessKey={setAccessKey}
                remainingCredits={remainingCredits}
                setTotalResumes={setTotalResumes}
                setTotalDuplicates={setTotalDuplicates}
                totalResumes={totalResumes}
                totalDuplicates={totalDuplicates}
                historyData={historyData}
                setIsRegisterModalOpen={setIsRegisterModalOpen}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                isRegisterModalOpen={isRegisterModalOpen}
              />
            } />
            <Route path="/auth/user/reset-password" element={<ResetPassword />}></Route>
          </Routes>
        </main>
      </div>
    </Router>
  );
}


export default App;
